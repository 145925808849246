// import Siema from "siema";
// const siemaRef = new Siema({
//   selector: ".siema",
//   duration: 600,
//   easing: "ease-in-out",
//   perPage: 1,
//   startIndex: 0,
//   draggable: true,
//   multipleDrag: true,
//   threshold: 20,
//   loop: true,
//   rtl: false,
//   onInit: () => {
//       setInterval(() => {
//         siemaRef.next();
//       }, 3000);
//   },
//   onChange: () => {}
// });

import { addEvents } from "./menu";
import { createObserver } from "./imgs";
import { initForm } from "./form";

// core version + navigation, pagination modules:
import Swiper, { Pagination, Navigation, Autoplay } from "swiper";
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

addEvents();
createObserver();
initForm();

const swiper = new Swiper(".swiper-container", {
  // Optional parameters
  modules: [Navigation, Pagination, Autoplay],
  loop: true,
  slidesPerView: 1,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  // If we need pagination
  pagination: {
    el: ".swiper-pagination",
  },
});
