const menuTransition =
  "transform .5s ease-in-out, background-color .5s ease-in-out";

function getCurrentState() {
  return document.getElementById("menu").classList.contains("menu--opened")
    ? "opened"
    : "closed";
}

function getCurrentSectionIndex() {
  const str = document.getElementById("menu").dataset.sectionindex;

  return str ? Number.parseInt(str) : -1;
}

export function forwardSection() {
  const currentSectionIndex = getCurrentSectionIndex();
  const menuEl = document.getElementById("menu");
  const menuSectionsEl = document.getElementById("menuSections");
  const backMenuBtn = document.getElementById("backMenuBtn");
  const newSectionIndex = currentSectionIndex + 1;

  menuSectionsEl.style.transition = menuTransition;
  menuSectionsEl.style.transform = `translateX(calc(-100vw * ${newSectionIndex}))`;
  menuEl.dataset.sectionindex = newSectionIndex.toString();

  backMenuBtn.classList.remove("menu-back-btn--invisible");
}

function backSection() {
  const menuEl = document.getElementById("menu");
  const menuSectionsEl = document.getElementById("menuSections");
  const newSectionIndex = getCurrentSectionIndex() - 1;

  if (newSectionIndex === 0) {
    const backMenuBtn = document.getElementById("backMenuBtn");

    backMenuBtn.classList.add("menu-back-btn--invisible");
  }

  menuSectionsEl.style.transition = menuTransition;
  menuEl.dataset.sectionindex = newSectionIndex.toString();
  menuSectionsEl.style.transform = `translateX(calc(-100vw * ${newSectionIndex}))`;
}

function toggleMenu(sectionIndex = 0) {
  const menuEl = document.getElementById("menu");

  if (getCurrentState() === "opened") {
    menuEl.classList.remove("menu--opened");

    return;
  }

  const menuSectionsEl = document.getElementById("menuSections");
  const backMenuBtn = document.getElementById("backMenuBtn");

  menuSectionsEl.style.transition = "";
  menuSectionsEl.style.transform = `translateX(calc(-100vw * ${sectionIndex}))`;
  menuEl.dataset.sectionindex = sectionIndex.toString();

  if (sectionIndex === 0) {
    backMenuBtn.classList.add("menu-back-btn--invisible");
  } else {
    backMenuBtn.classList.remove("menu-back-btn--invisible");
  }

  menuEl.style.transition = menuTransition;
  menuEl.classList.add("menu--opened");
}

export function addEvents() {
  const menuBtn = document.getElementById("menuBtn");
  const closeMenuBtn = document.getElementById("closeMenuBtn");
  const menuNavLinks = document.querySelectorAll("#menuNav a");
  const backMenuBtn = document.getElementById("backMenuBtn");
  const mobileContactBtn = document.getElementById("mobileContactBtn");
  const successMenuSectionHomeBtn = document.getElementById(
    "successMenuSectionHomeBtn"
  );

  menuBtn.addEventListener("click", () => toggleMenu());
  closeMenuBtn.addEventListener("click", () => toggleMenu());
  Array.from(menuNavLinks).forEach(el =>
    el.id !== "menuDemonstracaoBtn"
      ? el.addEventListener("click", () => toggleMenu())
      : void 0
  );
  document
    .getElementById("menuDemonstracaoBtn")
    .addEventListener("click", () => forwardSection());
  backMenuBtn.addEventListener("click", backSection);
  mobileContactBtn.addEventListener("click", () => toggleMenu(1));
  successMenuSectionHomeBtn.addEventListener("click", () => toggleMenu());
}
