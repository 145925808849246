import VMasker from "vanilla-masker";
import isEmail from "validator/es/lib/isEmail";
import { forwardSection } from "./menu";

const forms = {};

function onForm0RecaptchaSuccess(token) {
  const submitBtn = document.getElementById("form0-submitBtn");
  const form = forms["form0"];

  form.captchaToken = token;

  checkIfSubnmitBtnCanBeEnabled(form, submitBtn);
}

function onForm0RecaptchaExp() {
  const submitBtn = document.getElementById("form0-submitBtn");
  const form = forms["form0"];

  form.captchaToken = "";

  grecaptcha.reset();
  checkIfSubnmitBtnCanBeEnabled(form, submitBtn);
}

function onForm1RecaptchaSuccess(token) {
  const submitBtn = document.getElementById("form1-submitBtn");
  const form = forms["form1"];

  form.captchaToken = token;

  checkIfSubnmitBtnCanBeEnabled(form, submitBtn);
}

function onForm1RecaptchaExp() {
  const submitBtn = document.getElementById("form1-submitBtn");
  const form = forms["form1"];

  form.captchaToken = "";

  grecaptcha.reset();
  checkIfSubnmitBtnCanBeEnabled(form, submitBtn);
}

function onSubmit(formPrefix) {
  return (e) => {
    e.preventDefault();

    const form = forms[formPrefix];
    const obj = {
      nome: form.name,
      email: form.email,
      empresa: form.company,
      telefone: form.phone,
      captcha: form.captchaToken,
    };

    fetch("https://api5.webgeo.io/ouv/contato", {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "content-type": "application/json",
      },
    })
      .then(() => {
        if (formPrefix === "form0") {
          forwardSection();
          document.getElementById("menu").scroll(0, 0);
        } else if (formPrefix === "form1") {
          document.getElementById("contactSectionScreens").style.transform =
            "translateX(-53rem)";
        }

        document
          .getElementById(`${formPrefix}-successReq`)
          .classList.add("success-req--visible");
      })
      .catch((e) => alert("erro: " + e));
  };
}

function checkIfSubnmitBtnCanBeEnabled(form, submitBtn) {
  if (
    form.captchaToken &&
    form.name !== "" &&
    form.company !== "" &&
    form.email !== "" &&
    isEmail(form.email)
  ) {
    submitBtn.removeAttribute("disabled");

    return;
  }

  submitBtn.setAttribute("disabled", "");
}

export function initForm() {
  const formEls = document.querySelectorAll("form[data-formprefix]");

  formEls.forEach((formEl) => {
    const formPrefix = formEl.dataset.formprefix;
    const form = {
      name: "",
      company: "",
      email: "",
      captchaToken: "",
      phone: "",
    };
    forms[formEl.dataset.formprefix] = form;

    const submitBtn = document.getElementById(`${formPrefix}-submitBtn`);
    const nameInput = document.getElementById(`${formPrefix}-name`);
    const companyInput = document.getElementById(`${formPrefix}-company`);
    const emailInput = document.getElementById(`${formPrefix}-email`);
    const phoneInput = document.getElementById(`${formPrefix}-phone`);

    submitBtn.addEventListener("click", onSubmit(formPrefix));

    nameInput.addEventListener("blur", ({ target }) => {
      form.name = target.value;

      checkIfSubnmitBtnCanBeEnabled(form, submitBtn);
    });

    companyInput.addEventListener("blur", ({ target }) => {
      form.company = target.value;

      checkIfSubnmitBtnCanBeEnabled(form, submitBtn);
    });

    emailInput.addEventListener("blur", ({ target }) => {
      form.email = target.value;

      checkIfSubnmitBtnCanBeEnabled(form, submitBtn);
    });

    phoneInput.addEventListener("blur", ({ target }) => {
      VMasker(target).unMask();
      form.phone = target.value;
      VMasker(phoneInput).maskPattern("(99) 99999-9999");

      checkIfSubnmitBtnCanBeEnabled(form, submitBtn);
    });

    VMasker(phoneInput).maskPattern("(99) 99999-9999");
  });

  window.onForm0RecaptchaSuccess = onForm0RecaptchaSuccess;
  window.onForm0RecaptchaExp = onForm0RecaptchaExp;
  window.onForm1RecaptchaSuccess = onForm1RecaptchaSuccess;
  window.onForm1RecaptchaExp = onForm1RecaptchaExp;
}
