function onIntersect(entries, observer) {
  entries.forEach(({ isIntersecting, target }) => {
    if (isIntersecting && target.src !== target.dataset.src) {
      target.setAttribute("src", target.dataset.src);
      target.setAttribute("srcset", target.dataset.srcset);
    }
  });
}

export function createObserver() {
  const observer = new IntersectionObserver(onIntersect, {
    threshold: 0.2,
  });
  const imgs = document.querySelectorAll("img[data-src], img[data-srcset]");
  // const imgs = document.querySelectorAll(".lazy");

  imgs.forEach((el) => observer.observe(el));
}
